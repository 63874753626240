<template>
  <a-modal
    title="图标添加"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form-model
        ref="ruleForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="图标名称" ref="icon_name" prop="icon_name">
          <a-input v-model="info.icon_name" />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { addIcon } from "@/api/daily_life";
export default {
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      info: {
        icon_name: "",
      },
      rules: {
        icon_name: [
          { required: true, message: "请输入图标名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleCancel() {
      this.info.icon_name = "";
      this.visible = false;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          addIcon(this.info.icon_name)
            .then((res) => {
              this.confirmLoading = false;
              if (res.data.code === "500") {
                this.$message.error(res.data.result);
              } else {
                this.$message.success(res.data.result);
                this.info.icon_name = "";
                this.visible = false;
                this.$parent.$parent.fetch();
                this.$forceUpdate();
              }
            })
            .catch((err) => {
              this.confirmLoading = false;
              this.$message.error(err.toString());
            });
        } else {
          this.$message.error("请检查输入是否合法");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
