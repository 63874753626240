<template>
  <div>
    <a-card :bordered="false" class="content" style="width: 100%">
      <div>
        <a-button
          type="primary"
          icon="plus"
          @click="$refs.iconModal.visible = true"
          >添加图标</a-button
        >
        <a-input-search
          placeholder="全局搜索"
          style="width: 300px; float: right"
          v-model="globalSearch"
          @search="onSearch"
        />
      </div>
      <a-row
        type="flex"
        justify="space-around"
        align="top"
        style="margin-top: 10px"
        v-for="(iconArr, index) in icons"
        :key="index"
      >
        <a-col :span="3" v-for="icon in iconArr" :key="icon.id">
          <a-button
            :icon="icon.icon_name"
            size="large"
            v-if="icon.icon_name !== ''"
          ></a-button>
        </a-col>
      </a-row>
      <icon-modal ref="iconModal" @ok="handleCreate" />
    </a-card>
    <a-back-top />
  </div>
</template>

<script>
import IconModal from "./modules/IconModal";
import { getIcon } from "@/api/daily_life";
export default {
  components: {
    IconModal,
  },
  data() {
    return {
      icons: [],
      globalSearch: "",
    };
  },
  methods: {
    onSearch() {
      this.fetch({ page_size: 1000, page: 1, search: this.globalSearch });
    },
    fetch(query) {
      getIcon(query)
        .then((res) => {
          this.icons.splice(0, this.icons.length);
          let temp = [];
          for (let i = 0; i < res.data.results.length; i++) {
            temp.push(res.data.results[i]);
            if (i % 8 === 7 || i === res.data.results.length - 1) {
              // 该导入了
              console.log(8 - temp.length);
              let temp_length = temp.length;
              for (let j = 0; j < 8 - temp_length; j++) {
                let icon = {
                  id: temp[temp.length - 1].id + 1,
                  icon_name: "",
                };
                temp.push(icon);
              }
              this.icons.push(temp);
              temp = [];
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    handleCreate() {
      this.fetch({ page_size: 1000, page: 1 });
    },
  },
  mounted() {
    this.fetch({ page_size: 1000, page: 1 });
  },
};
</script>
<style scoped>
.trigger {
  line-height: 64px;
  font-size: 20px;
}
</style>
